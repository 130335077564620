import { throttle } from 'lodash';

export function scrollAction() {
  window.addEventListener(
    'scroll',
    _.throttle(
      e => {
        // ここに処理

        //追従バナー
        const followBtn = document.querySelector('.js-followBtn');
        var y = window.pageYOffset ;
        if ( y > 0 ) {
          followBtn.classList.add('is-view');
        } else {
          followBtn.classList.remove('is-view');
        }
        return false;
      },
      50,
      { trailing: true, leading: true }
    )
  );
}